import Vue from "vue";
import Vuex from "vuex";

import Ajax from "@/utils/ajax";
//import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    branchoffice: {
      nombre: "",
      autoServicioDisponible: true,
      pruebas: []
    },
    listBranchoffice: []
  },
  actions: {
    async getEmployee(_, payload) {

      console.log(payload)
      try {
        const resp = await Ajax.get("/employee/", { params: payload });
        //  console.log(resp.data)
        return Promise.resolve(resp.data);
      } catch (error) {
        return Promise.reject(error.response.data);
      }
    },
    async postEmbarazada(_, data) {
      try {
        const resp = await Ajax.post("/embarazada", data )
        return Promise.resolve(resp.data)
      } catch (e) {
        return Promise.reject(e.response.data);
      }
    },
  },


  mutations: {

  }
});
